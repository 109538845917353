import { template as template_8da28c09f7b941c0bf4b927b092b6fd1 } from "@ember/template-compiler";
const FKText = template_8da28c09f7b941c0bf4b927b092b6fd1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
