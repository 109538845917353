import { template as template_3953c8b319ba4362aea48d5bb5076200 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3953c8b319ba4362aea48d5bb5076200(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
